<template>
    <form class="dropzone">
        <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->

        <div id="dropzone-message" style="display: none">
            <span class="dropzone-title">Drop files here</span>
        </div>

    </form>
</template>

<script>
import Dropzone from 'dropzone'
import '@/../node_modules/dropzone/dist/dropzone.css'
import jobService from '@/services/jobs'

Dropzone.autoDiscover = false

export default {
    name: 'dropzone',
    props: {
        jobParams: Object
    },
    data () {
        return {
            fileList: [],
            loading: false,
            loadingColour: '',
            progress: 100,
            // status: ''
        }
    },
    mounted () {
        const vm = this

        let options = {
            // The URL will be changed for each new file being processing
            url: '/',

            // Since we're going to do a `PUT` upload to S3 directly
            method: 'put',

            // Hijack the xhr.send since Dropzone always upload file by using formData
            // ref: https://github.com/danialfarid/ng-file-upload/issues/743
            sending (file, xhr) {
                let _send = xhr.send
                xhr.send = () => {
                _send.call(xhr, file)
                }
            },

            // Upload one file at a time since we're using the S3 pre-signed URL scenario
            parallelUploads: 1,
            uploadMultiple: false,

            // Content-Type should be included, otherwise you'll get a signature
            // mismatch error from S3. We're going to update this for each file.
            header: '',

            // Customize the wording
            dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,

            // We're going to process each file manually (see `accept` below)
            autoProcessQueue: false,

            timeout: 0,
            maxFilesize: 1024,

            // Here we request a signed upload URL when a file being accepted
            async accept (file, done) {
                jobService.create(
                    file,
                    this.jobParams.jobType.name,
                    jobService.widgetToSczParams(
                        this.jobParams.playbackDuration,
                        this.jobParams.transitionDuration
                    )
                )
                .then(job => {
                    file.uploadUrl = job.upload_url
                    file.jobId = job.job_id
                    setTimeout(() => vm.dropzone.processFile(file))
                    done()
                })
                .catch((err) => {
                    if ( err.response && err.response.data && err.response.data.msg ) {
                        done(err.response.data.msg)
                    } else {
                        done(err)
                    }
                })
            }
        }

        // Instantiate Dropzone
        this.dropzone = new Dropzone(this.$el, options)
        this.dropzone.jobParams = this.jobParams

        // Set signed upload URL for each file
        vm.dropzone.on('processing', (file) => {
            vm.dropzone.options.url = file.uploadUrl
        })

        //Emmet Addition - to capture file
        vm.dropzone.on('success', (file) => {
            this.$emit('job-created', file.jobId)
        })
    }
}
</script>

<style>
form.dropzone {
    transition: all 0s linear;
    border: 2px dashed #ccc;
    background-color: #fafafa;
    /* min-height: 200px; */
    /* width: 80%; */
    /* margin: 26px auto 0 auto; */
    height: 400px;
    margin: 24px auto;
    overflow: auto;
}

form.dropzone:hover {
    border-color: #3498db;
    background-color: white;
}

#dropzone-message {
    color: #666;
}

span {
    line-height: 1.8;
    font-size: 13px;
    letter-spacing: 0.4px;
}

span.dropzone-title {
    display: block;
    color: #888;
    font-size: 2em;
}

span.dropzone-info {
    display: block;
    color: #a8a8a8;
}
</style>
